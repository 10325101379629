.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  text-align: left;  /* Left-aligns all h1 elements globally */ /* Optional: Adds some left margin for spacing */
}

.mtb-title {
  text-align: left;
  font-size: 2em;
  padding: 0 10%;
}

.mtb-logo {
  max-width: 150px; /* Adjust the max width */
  height: auto; /* Maintain aspect ratio */
  display: block;
  margin: 0 auto; /* Center the logo horizontally */
  padding: 20px 0; /* Add some padding to the top and bottom */
}

